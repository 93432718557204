import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import MemoryStorage from 'redux-persist-memory-storage';
import promise from "redux-promise-middleware";
import logger from "redux-logger";
import ReduxThunk from 'redux-thunk';
import axios from 'axios';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers/index'; // the value from combineReducers
import {clearSession, getSessionItem, getToken} from './utilities';
import {ROUTES} from './routes';
import {toast} from 'react-toastify';

const middleware = applyMiddleware(ReduxThunk, logger);

const persistConfig = {
	key: 'root',
	storage: new MemoryStorage()
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, compose(middleware));
export const persistor = persistStore(store);

// Send access token in every request
axios.interceptors.request.use(function (config) {
  const accessToken = getToken() ? getSessionItem('token') : null;
  // Explicit token
  if(typeof config.headers.Authorization === 'undefined'){
    if(accessToken !== null && typeof config.token === 'undefined'){
      config.headers = { ...config.headers, Authorization: `Bearer ${accessToken}` };
    }
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});


// Add a response interceptor to check user session
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // If session is unauthorised, then logout the user.
  if(
    typeof error.response !== 'undefined' 
    && typeof error.response.status !== 'undefined'){
    if(error.response.status === 401){
      // Redirect to login page
      if(typeof error.response.config.verifyAuth === 'undefined' || error.response.config.verifyAuth === true){
        toast.error('Your are logged out.');
        clearSession(`${ROUTES.LOGIN.path}?redirectTo=${window.location.pathname}`);
        
        return Promise.reject(error);
      }
    }else{
      if(error.response.data && error.response.data.message){
        toast.error(error.response.data.message);
      }else if(error.response.data && error.response.data.error && error.response.data.error.category === undefined){
        toast.error(error.response.data.message);
      }
    }
  }
  // Do something with response error
  return Promise.reject(error);
});