import { load } from "./utilities";
import React from "react";
import { Redirect } from "react-router-dom";
import { editPoll } from "./actions/polls";

export const ROUTES = {
  ROOT: {
    path: "/",
    exact: true,
    render: () => <Redirect to="/login" />,
  },
  ADMIN: {
    path: "/admin",
    component: load("Admin"),
  },
  VIEWDASHBOARD: {
    path: "/admin",
    exact: true,
    component: load("dashboard/Dashboard"),
  },
  MANAGEADMIN: {
    path: "/admin/manage-admin",
    exact: true,
    component: load("users/ManageAdmin"),
  },
  REGISTER: {
    path: "/admin/register",
    exact: true,
    component: load("users/Register"),
  },
  MANAGEPOLL: {
    path: "/admin/poll-management",
    exact: true,
    component: load("polls/PollManagement"),
  },
  POLLDETAILS: {
    path: "/admin/poll-details/:id",
    exact: true,
    component: load("polls/PollDetails"),
  },
  EDITPOLL: {
    path: "/admin/edit-poll/:id",
    exact: true,
    component: load("polls/EditPoll"),
  },
  MANAGEROLE: {
    path: "/admin/role-management",
    exact: true,
    component: load("roles/RoleManagement"),
  },
  MANAGECOUPON: {
    path: "/admin/coupon-management",
    exact: true,
    component: load("couponManagement/CouponManagement")
  },
  LOGOUT: {
    path: "/logout",
    exact: true,
    component: load("Logout"),
  },
  LOGIN: {
    path: "/login",
    exact: true,
    component: load("Login"),
  },
  RESETPASSWORD: {
    path: '/admin/change-password/:resetToken',
    exact: true,
    component: load('ResetPassword')
  },
  CHANGEPASSWORD: {
    path: "/admin/changePassword",
    exact: true,
    component: load("changePassword/ChangePassword"),
  },
  NOTFOUND: {
    component: load("Sidebar"),
  },
};
